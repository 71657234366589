var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"board-card gl-border gl-relative gl-mb-3 gl-rounded-base gl-border-section gl-bg-section gl-leading-normal hover:gl-bg-subtle dark:hover:gl-bg-gray-200",class:[
    {
      'multi-select gl-border-blue-200 gl-bg-blue-50': _vm.multiSelectVisible,
      'gl-cursor-grab': _vm.isDraggable,
      'is-active !gl-bg-blue-50 hover:!gl-bg-blue-50': _vm.isActive,
      'is-disabled': _vm.isDisabled,
      'gl-cursor-not-allowed gl-bg-gray-10': _vm.item.isLoading,
    },
  ],attrs:{"index":_vm.index,"data-item-id":_vm.item.id,"data-item-iid":_vm.item.iid,"data-item-path":_vm.item.referencePath,"data-testid":"board-card"}},[_c('button',{staticClass:"board-card-button gl-block gl-h-full gl-w-full gl-rounded-base gl-border-0 gl-bg-transparent gl-p-4 gl-text-left gl-outline-none focus:gl-focus",class:[
      {
        'focus:gl-bg-subtle dark:focus:gl-bg-gray-200': _vm.showFocusBackground,
        'gl-border-l-4 gl-pl-4 gl-border-l-solid': _vm.itemColor,
      },
    ],style:(_vm.cardStyle),attrs:{"id":_vm.boardItemUniqueId,"aria-label":_vm.item.title,"data-col-index":_vm.columnIndex,"data-row-index":_vm.rowIndex,"data-testid":"board-card-button"},on:{"click":_vm.toggleIssue,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.focusLeft.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.focusRight.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.focusNext.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.focusPrev.apply(null, arguments)}]}},[_c('board-card-inner',{attrs:{"list":_vm.list,"item":_vm.formattedItem,"update-filters":true,"index":_vm.index,"show-work-item-type-icon":_vm.showWorkItemTypeIcon},on:{"setFilters":function($event){return _vm.$emit('setFilters', $event)}}},[_vm._t("default")],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }